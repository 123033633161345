<template>
  <div>
    <el-input :size="size" v-if="readonly" ref="input" :value="readonlyText" readonly/>
    <el-cascader v-else
                :size="size"
                clearable
                :placeholder="placeholder"
                 ref="areaCascader"
                 v-model.trim="defaultValue"
                 :props="cascaderProps"
                 @blur="handleBlue"
                 @change="handleChange">
    </el-cascader>
  </div>
</template>
<script>
import {getDistrictListByPid} from '@/api/userCenter';

export default {
  name: 'area_cascader',
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default () {
        return '请选择地址';
      },
    },
    size: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    includeCountry: {
      type: Boolean,
      default: false,
    },
    countryId: {
      type: String,
    },
    countryName: {
      type: String,
    },
    provinceId: {
      type: String,
    },
    provinceName: {
      type: String,
    },
    cityId: {
      type: String,
    },
    cityName: {
      type: String,
    },
    areaId: {
      type: String,
    },
    areaName: {
      type: String,
    },
  },
  data () {
    return {
      readonlyText: '',
      defaultValue: [],
      dataCache: new Map(),
      cascaderProps: {
        lazy: true,
        value: 'id',
        label: 'fullName',
        lazyLoad: (node, resolve) => {
          const {
            level,
            value = 0,
          } = node;
          let maxLevel = this.includeCountry ? 3 : 2;
          if (level <= maxLevel) {
            getDistrictListByPid({
              pid: value,
              onlyFindCountryProvinceFlag: level === 0 ? !this.includeCountry : false,
              excludeCountry: level === 0 ? !this.includeCountry : false,
            }).then(res => {
              let result = [];
              res.body && res.body.forEach(t => {
                this.dataCache.set(t.id, t);
                if (this.includeCountry && level === 0) {
                  result.push(Object.assign({leaf: t.adcode !== 'CHN'}, t));
                } else {
                  result.push(level === maxLevel ? Object.assign({leaf: true}, t) : t);
                }
              });
              resolve(result);
            });
          } else {
            resolve();
          }
        },
      },
    };
  },
  methods: {
    handleChange (val) {
      if (val.length === 0) {
        this.$emit('update:provinceId', '');
        this.$emit('update:provinceName', '');
        this.$emit('update:cityId', '');
        this.$emit('update:cityName', '');
        this.$emit('update:areaId', '');
        this.$emit('update:areaName', '');
        if (this.includeCountry) {
          this.$emit('update:countryId', '');
          this.$emit('update:countryName', '');
        }
        return;
      }
      if (this.includeCountry) {
        this.$emit('update:countryId', val[0]);
        this.$emit('update:countryName', this.dataCache.get(val[0]).fullName);
        this.$emit('update:provinceId', val[1]);
        this.$emit('update:provinceName', val[1] ? this.dataCache.get(val[1]).fullName : '');
        this.$emit('update:cityId', val[2]);
        this.$emit('update:cityName', val[2] ? this.dataCache.get(val[2]).fullName : '');
        this.$emit('update:areaId', val[3]);
        this.$emit('update:areaName', val[3] ? this.dataCache.get(val[3]).fullName : '');
      } else {
        this.$emit('update:provinceId', val[0]);
        this.$emit('update:provinceName', this.dataCache.get(val[0]).fullName);
        this.$emit('update:cityId', val[1]);
        this.$emit('update:cityName', this.dataCache.get(val[1]).fullName);
        this.$emit('update:areaId', val[2]);
        this.$emit('update:areaName', this.dataCache.get(val[2]).fullName);
      }
    },
    handleBlue () {
      this.$emit('handleValidate');
    },
    setDefaultValue () {
      let temp = [];
      if (this.includeCountry) {
        this.countryName && temp.push(this.countryName);
      }
      this.provinceName && temp.push(this.provinceName);
      this.cityName && temp.push(this.cityName);
      this.areaName && temp.push(this.areaName);

      if (this.readonly) {
        this.readonlyText = temp.join(' / ');
      } else {
        this.$nextTick(() => {
          this.$refs.areaCascader && (this.$refs.areaCascader.presentText = temp.join(' / '));
        });
      }
    },
  },
  watch: {
    provinceId () {
      if (this._props.provinceId === '' && this.$refs.areaCascader) {
        this.$refs.areaCascader.$refs.panel.clearCheckedNodes();
        this.$refs.areaCascader.$refs.panel.activePath = [];
      }
      this.setDefaultValue();
    },
  },
  created () {
    this.setDefaultValue();
  },
};
</script>
