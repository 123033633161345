<!--
 * @Author: your name
 * @Date: 2022-04-18 15:18:44
 * @LastEditTime: 2022-04-18 15:18:48
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/PersonalCenter/components/phone.vue
-->
<template>
  <el-dialog
    :title="currentTitle"
    :visible.sync="isShow"
    width="500px"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='true'
  >
    <div class="content" style="max-height: 400px;overflow: auto;">
      <el-form
        :model="JZChangePhoneForm"
        ref="JZForm"
        :rules="JZChangePhoneFormRules"
        :inline="true"
        class="content_block JZForm"
        label-width="125px"
      >
        <el-row >
          <el-col :span="24">
            <el-form-item
                prop="phone"
                label="原手机号："
              >
              {{JZChangePhoneForm.phone}}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="code"
                label="验证码："
              >
              <el-input v-model.trim="JZChangePhoneForm.code" placeholder="请输入验证码" >
                <el-button type="default" slot="append" size="mini" @click.native="sendCaptcha(1)">{{oldMsgTip}}</el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="newPhone"
                label="新手机号："
              >
             <el-input v-model.trim="JZChangePhoneForm.newPhone" maxlength="11" show-word-limit placeholder="请输入新手机号" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                prop="newCode"
                label="验证码："
              >
              <el-input v-model.trim="JZChangePhoneForm.newCode" placeholder="请输入验证码" >
                <el-button type="default" slot="append" size="mini" @click.native="sendCaptcha(2)">{{newMsgTip}}</el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer" style="text-align:right;" >
        <el-button @click="isShow=false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleEnsure" size="mini">确定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {changePhone} from '@/api/userCenter';
import { sendCaptcha} from '@/api/login';
export default {
  name: '',
  methods: {
    initPage () {
      this.isShow = true;
      this.$nextTick(() => {
        this.$refs.JZForm.clearValidate();
      });
    },
    handleEnsure () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          let currentParams = {
            newCode: this.JZChangePhoneForm.newCode,
            oldCode: this.JZChangePhoneForm.code,
            newPhone: this.JZChangePhoneForm.newPhone,
          };
          changePhone(currentParams).then(() => {
            this.status = '';
            this.isShow = false;
            let currentInfo = this.userInfo;
            currentInfo = {
              ...currentInfo,
              phone: this.JZChangePhoneForm.newPhone,
              identity: this.$store.state.User.userInfo.identity,
            };
            this.$store.dispatch('changeUserInfo', currentInfo);
            this.$emit('getDetailInfo');
          });
        }
      });
    },
    sendCaptcha (index) {
      let captchaType = [1].includes(index) ? 'CHANGE_PHONE_OLD' : 'CHANGE_PHONE_NEW';
      let phone = index === 1 ? this.JZChangePhoneForm.phone : this.JZChangePhoneForm.newPhone;
      sendCaptcha({ phone, captchaType }).then(() =>{
        if (!this.loginMsgTimerId && [1].includes(index)) {
          this.loginMsgTimerTick = 120;
          this.loginMsgTimerId = setInterval(() => {
            this.loginMsgTimerTick--;
            if ([1, 3].includes(index)) {
              this.oldMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            } else {
              this.newMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            }
            if (this.loginMsgTimerTick === 0) {
              clearInterval(this.loginMsgTimerId);
              this.loginMsgTimerId = '';
              if ([1, 3].includes(index)) {
                this.oldMsgTip = '获取验证码';
              } else {
                this.newMsgTip = '获取验证码';
              }
            }
          }, 1000);
        }
        if (!this.loginConfirmPasswordTimerId && index === 2) {
          this.loginConfirmPasswordMsgTimerTick = 120;
          this.loginConfirmPasswordTimerId = setInterval(() => {
            this.loginConfirmPasswordMsgTimerTick--;
            if (index === 1) {
              this.oldMsgTip = `重新获取(${this.loginConfirmPasswordMsgTimerTick})`;
            } else {
              this.newMsgTip = `重新获取(${this.loginConfirmPasswordMsgTimerTick})`;
            }
            if (this.loginConfirmPasswordMsgTimerTick === 0) {
              clearInterval(this.loginConfirmPasswordTimerId);
              this.loginConfirmPasswordTimerId = '';
              if (index === 1) {
                this.oldMsgTip = '获取验证码';
              } else {
                this.newMsgTip = '获取验证码';
              }
            }
          }, 1000);
        }
      });
    },
  },
  data () {
    return {
      currentTitle: '修改手机号',
      isShow: false,
      oldMsgTip: '获取验证码',
      newMsgTip: '获取验证码',
      loginMsgTimerId: null,
      loginConfirmPasswordTimerId: null,
      JZChangePhoneForm: {
        phone: '',
        code: '',
        newPhone: '',
        newCode: '',
      },
      JZChangePhoneFormRules: {
        phone: [
          {
            required: true,
            message: '请输入原手机号',
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: ['blur', 'change'],
          },
        ],
        newPhone: [
          {
            required: true,
            message: '请输入新手机号',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^1\d{10}$/,
            message: '新手机号码格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        newCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
